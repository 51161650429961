import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Col, Row, Button, ButtonToolbar } from 'reactstrap';
import ContactIcon from 'mdi-react/ScaleBalanceIcon';
import PropTypes from 'prop-types';
import UserIcon from 'mdi-react/UserIcon';
import PriceIcon from 'mdi-react/CashIcon';

const LineForm = ({ handleChange, handleSubmit, value, isDisabled }) => (
  <form className="form" onSubmit={handleSubmit}>
    
    <div className="form__form-group">
      <span className="form__form-group-label">Line 1</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="line1"
          component="input"
          type="text"
          placeholder="Line 1"
          disabled={isDisabled}
          required
        />
      </div>
    </div>
    
    <div className="form__form-group">
      <span className="form__form-group-label">Line 2</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <ContactIcon />
        </div>
        <Field
          name="line2"
          component="input"
          type="text"
          disabled={isDisabled}
          placeholder="Line 2"
        />
      </div>
    </div>

    <div className="form__form-group">
      <span className="form__form-group-label">Line 3</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <ContactIcon />
        </div>
        <Field
          name="line3"
          component="input"
          type="text"
          disabled={isDisabled}
          placeholder="Line 3"
        />
      </div>
    </div>

    <div className="form__form-group">
      <span className="form__form-group-label">Line 4</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <ContactIcon />
        </div>
        <Field
          name="line4"
          component="input"
          type="text"
          disabled={isDisabled}
          placeholder="Line 4"
        />
      </div>
    </div>

    <div className="form__form-group">
      <span className="form__form-group-label">Line 5</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <ContactIcon />
        </div>
        <Field
          name="line5"
          component="input"
          type="text"
          disabled={isDisabled}
          placeholder="Line 5"
        />
      </div>
    </div>
    
    <ButtonToolbar className="form__button-toolbar">
      <Button color="primary" type="submit" disabled={isDisabled}>Submit</Button>
    </ButtonToolbar>
  </form>
);

LineForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  value: PropTypes.object,
  isDisabled: PropTypes.bool
};
  
export default reduxForm({ form: 'lineform' })(LineForm);
