import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import CalendarIcon from 'mdi-react/CalendarIcon';
import DeliveryListTable from './components/DeliveryListTable';
import Config from '../Config';

const getFrontId = (date) => {
  return (((date.getFullYear() % 100) * 10000) + ((date.getMonth() + 1) * 100) + date.getDate()).toString();
};

const getDateString = (timestamp) => {
  const now = new Date(timestamp);
  return now.getFullYear() + '-' + ('0' + (now.getMonth() + 1).toString()).slice(-2) + '-' + ('0' + now.getDate().toString()).slice(-2);
};

class DashboardPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    token: PropTypes.string,
    updateConfig: PropTypes.func
  };

  state = {
    selected_start_date: getDateString(this.getOneMonthAgo().getTime() > new Date('17 OCT 2018').getTime() ? new Date().getTime() : new Date('17 OCT 2018').getTime()),
    selected_end_date: getDateString(this.getTomorrow().getTime()),
    startDate: this.getOneMonthAgo().getTime() > new Date('17 OCT 2018').getTime() ? new Date().getTime() : new Date('17 OCT 2018').getTime(),
    endDate: this.getTomorrow().getTime(),
    enableSlider: true,
    error: '',
    data: [],
    total: 0,
    cost: 0,
    breakdown: [],
    budget: [],
    export: []
  };

  componentDidMount() {
    setTimeout(() => {
      this.fetch();
    }, 600);
  }

  getTomorrow() {
    const d = new Date();
    d.setHours(23, 59, 59, 59);
    d.setDate(d.getDate() + 1);
    return d;
  }

  getOneMonthAgo() {
    const d = new Date();
    d.setMonth(d.getMonth() - 1);
    console.log(d.getTime());
    return d;
  }

  updateDateRange = (props) => {
    if (props[0] !== this.state.startDate || props[1] !== this.state.endDate) {
      this.setState({
        enableSlider: false,
        startDate: props[0],
        endDate: props[1]
      }, () => {
        this.fetch();
      });
    }
  }

  formatFileDate = () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}${month}${year}`;
  }

  formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  formatTime = (date) => {
    const hour = date.getHours();
    const min = date.getMinutes();

    return `${('0' + hour.toString()).slice(-2)}:${('0' + min.toString()).slice(-2)}`;
  }

  fetch = () => {
    this.setState({ 
      error: '',
      enableSlider: false,
      data: []
    });

    const xhr = this.createCORSRequest('POST', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/deliveries');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    const params = {
      start_date: this.state.startDate,
      end_date: this.state.endDate,
    };

    console.log('params', params);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        console.log('b', body.orders);
        const d = [];

        for (let i = 0; i < body.orders.length; i++) {
          d.push(...body.orders[i].items.map((item) => {
            return {
              date: this.formatDate(new Date(body.orders[i].deliver_at)),
              time: this.formatTime(new Date(body.orders[i].deliver_at)),
              name: body.orders[i].details.name,
              item_name: item.name,
              price: (item.price / 100).toFixed(2),
              quantity: item.qty,
              total: ((item.price * item.qty) / 100).toFixed(2),
              gst: (((item.price * item.qty) / 100) * 0.07).toFixed(2),
              total_full: (((item.price * item.qty) / 100) * 1.07).toFixed(2),
              po_total: (body.orders[i].paid_amount / 100).toFixed(2),
              po_gst: ((body.orders[i].paid_amount / 100) * 0.07).toFixed(2),
              po_total_full: ((body.orders[i].paid_amount / 100) * 1.07).toFixed(2),
              delivered: body.orders[i].delivered,
              tracker: body.orders[i].delivery_id,
              invoice: getFrontId(new Date(body.orders[i].deliver_at)) + body.orders[i].delivery_id.toString().slice(-4),
              po: body.orders[i].po,
              deliver_date: body.orders[i].delivered_at ? this.formatDate(new Date(body.orders[i].delivered_at)) : 'None',
              deliver_time: body.orders[i].delivered_at ? this.formatTime(new Date(body.orders[i].delivered_at)) : 'None'
            };
          }));
        }

        const e = [['Order Number', 'PO Number', 'Delivery Date (DD/MM/YYYY)', 'Delivery Time (24hr)', 'Customer Name', 'Item Name', 'Unit Price', 'Qty', 'Total Price (Before GST)', 'Total GST', 'Total Price (After GST)', 'PO Total (Before GST)', 'PO Total GST', 'PO Total (After GST)', 'Status', 'Delivered Date (DD/MM/YYYY)', 'Delivered Time (24hr)']];
        e.push(...d.map(item => {
          return [
            item.invoice,
            item.po,
            item.date,
            item.time,
            item.name,
            item.item_name,
            item.price.toString(),
            item.quantity.toString(),
            item.total.toString(),
            item.gst.toString(),
            item.total_full.toString(),
            item.po_total.toString(),
            item.po_gst.toString(),
            item.po_total_full.toString(),
            item.delivered ? 'Delivered' : 'Pending',
            item.deliver_date,
            item.deliver_time
          ];
        }));

        this.setState({
          data: d,
          export: e
        });
      } else {
        this.setState({ error: body.message });
        console.log('perr', body.message, body);
      }
      this.setState({ 
        loading: false,
        enableSlider: true
      });
    };

    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  formatToDate = (datestr) => {
    const date = new Date();
    const ymd = datestr.split('-');
    date.setFullYear(parseInt(ymd[0], 10));
    date.setMonth(parseInt(ymd[1], 10) - 1);
    date.setDate(parseInt(ymd[2], 10));
    date.setHours(0, 0, 0, 0);
    return date;
  }

  formatToDatestr = (now) => {
    return now.getFullYear() + '-' + ('0' + (now.getMonth() + 1).toString()).slice(-2) + '-' + ('0' + now.getDate().toString()).slice(-2);
  }

  changedRange = () => {
    const start = this.formatToDate(this.state.selected_start_date);
    const end = this.formatToDate(this.state.selected_end_date);
    const update = {
      enableSlider: false,
      startDate: start.getTime(),
      endDate: end.getTime()
    };

    if (start >= end) {
      end.setDate(start.getDate() + 1);
      update['endDate'] = end.getTime();
      update['selected_end_date'] = this.formatToDatestr(end);
    }

    this.setState(update, () => {
      this.fetch();
    });
  }

  render() {
    return (
      <Container className="dashboard">
        <Alert color="danger" className="bold-text" isOpen={this.state.error}>
          <div className="alert__content">
            {this.state.error}
          </div>
        </Alert>
        <Row>
          <Col md={10}>
            <h3 className="page-title">Dashboard</h3>
          </Col>
          <Col md={2}>
            <CSVLink className="btn btn-success" filename={`DeliveryManReport_${this.formatFileDate()}.csv`} data={this.state.export}>Export to Excel</CSVLink>
          </Col>
        </Row>
        <Row>
          {/*<DoubleSliderType 
            start={this.state.startDate} 
            end={this.state.endDate} 
            enabled={this.state.enableSlider} 
            update={this.updateDateRange}
            label="Based on the date the order is created"
          />*/}
          
          <Card md={12}>
            <CardBody>
              <Row className="form">
                <Col md={3} className="form__form-group">
                  <span className="form__form-group-label">Start Date</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <CalendarIcon />
                    </div>
                    <input
                      name="start_date"
                      type="date"
                      value={this.state.selected_start_date}
                      onChange={e => {
                        this.setState({ selected_start_date: e.target.value }, () => this.changedRange());
                      }}
                    />
                  </div>
                </Col>

                <Col md={3} className="form__form-group">
                  <span className="form__form-group-label">End Date</span>
                  <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                      <CalendarIcon />
                    </div>
                    <input
                      name="end_date"
                      type="date"
                      value={this.state.selected_end_date}
                      onChange={e => {
                        this.setState({ selected_end_date: e.target.value }, () => this.changedRange());
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <DeliveryListTable data={this.state.data} />
        </Row>
      </Container>
    );
  }
}

export default DashboardPage;
