import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import ConfirmationCard from './components/ConfirmationCard';

class OrderConfirmPage extends Component {
  render() {
    return (
      <Container>
        <Row>
          <ConfirmationCard />
        </Row>
      </Container>
    );
  }
}

export default OrderConfirmPage;
