import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class CatalogItem extends Component {
  static propTypes = {
    config: PropTypes.object,
    update: PropTypes.func,
    loadelements: PropTypes.array
  };

  render() {
    return (
      <Col md={12} lg={12}>
        <div className="catalog-items__wrap">
          <div className="catalog-items">
            {this.props.loadelements.map((item, i) =>
              (
                <Link className="catalog-item clickable" key={item.username} to={`/pages/element?uid=${item.username}&name=${item.name.replace('&', '%26')}`} >
                  <div className="catalog-item__link">
                    <div className="catalog-item__info">
                      <Row>
                        <Col md={9}>
                          <h4 className="catalog-item__title">{item.name}</h4>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </Col>
    );
  }
}

export default CatalogItem;
