const Units = [
  { value: 'ea', label: 'ea (each)' },
  { value: 'bag', label: 'bag' },
  { value: 'cm', label: 'cm (centimeter)' },
  { value: 'cup', label: 'cup' },
  { value: 'dl', label: 'dl (deciliter)' },
  { value: 'gall', label: 'gal (gallon)' },
  { value: 'gill', label: 'gill (1/2 cup)' },
  { value: 'g', label: 'g (gram)' },
  { value: 'in', label: 'in (inch)' },
  { value: 'kg', label: 'kg (kilogram)' },
  { value: 'l', label: 'l (liter)' },
  { value: 'm', label: 'm (meter)' },
  { value: 'mg', label: 'mg (milligram)' },
  { value: 'mm', label: 'mm (millimeter)' },
  { value: 'ml', label: 'ml (milliliter)' },
  { value: 'oz', label: 'oz (ounce)' },
  { value: 'packet', label: 'pkt (packet)' },
  { value: 'pint', label: 'pint' },
  { value: 'lb', label: 'lb (pound)' },
  { value: 'quart', label: 'quart' },
  { value: 'sachet', label: 'sachet' },
  { value: 'tbsp', label: 'tbsp (tablespoon)' },
  { value: 'tsp', label: 'tsp (teaspoon)' }
];

export default Units;
