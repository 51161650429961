import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CatalogItem from './components/CatalogItem';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';

class CategoriesPage extends Component {
  static propTypes = {
    updateConfig: PropTypes.func,
    config: PropTypes.object
  };

  state = {
    loading: true,
    error: '',
    categories: []
  };

  componentDidMount() {
    setTimeout(() => {
      if (this.props.config.user.expiry <= new Date().getTime()) {
        window.location = '/login';
      } else {
        this.load();
      }
    }, 600);
  }

  load = () => {
    const xhr = this.createCORSRequest('GET', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/category');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({
          categories: body.categories,
          loading: false
        });
      } else {
        console.log('e', body.message);
        this.setState({
          error: body.message,
          loading: false
        });
      }
    };

    xhr.send();
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#c88ffa"
        spinnerType="ball-clip-rotate"
        textColor="#c88ffa"
        logoSrc={Logo}
      >
        <Container>
          <Row>
            <Col md={10}>
              <h3 className="page-title">Categories</h3>
              <h3 className="page-subhead subhead">Add and edit top level groupings for vendors</h3>
            </Col>
            <Col md={2}>
              <Link className="btn btn-success" to="/pages/category">Add Category</Link>
            </Col>
          </Row>
          <Row>
            <CatalogItem loadcategories={this.state.categories} config={this.props.config} />
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default CategoriesPage;
