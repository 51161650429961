import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LogInForm from './components/LogInForm';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';

class LogIn extends Component {
  state = {
    loading: false
  };

  componentDidMount() {
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
  }

  start = () => {
    this.setState({
      loading: true
    });
  }

  stop = () => {
    this.setState({
      loading: false
    });
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <div className="account">
          <div className="account__wrapper">
            <div className="account__card">
              <div className="center">
                <img className="logo" src={Logo} alt="pretzel-logo" />
              </div>
              <div className="account__head">
                <h3 className="account__title">Welcome to
                  <span className="account__logo"> Pretz
                    <span className="account__logo-accent"> DeliveryMan</span>
                  </span>
                </h3>
                <h4 className="account__subhead subhead">Manage your delivery easily</h4>
              </div>
              <LogInForm start={this.start} stop={this.stop} update={this.props.update} config={this.props.config} menu={this.props.menu} location={this.props.location} />
            </div>
          </div>
        </div>
      </LoadingScreen>
    );
  }
}

LogIn.propTypes = {
  location: PropTypes.object,
  update: PropTypes.func,
  menu: PropTypes.func,
  config: PropTypes.object
};

export default LogIn;

// if you want to add select, date-picker and time-picker in your app you need to uncomment the first
// four lines in /scss/components/form.scss to add styles
