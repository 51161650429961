/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { notify } from 'react-notify-toast';
import Config from '../../Config';

export default class Catalog extends Component {
  static propTypes = {
    config: PropTypes.object,
    onComplete: PropTypes.func
  };

  state = {
    ingredients: []
  };

  componentDidMount() {
    /*notify.show('Loading, please wait', 'custom', 5000, {
      background: '#343a40',
      text: '#ddae71'
    });*/

    setTimeout(() => {
      if (this.props.config.user.expiry <= new Date().getTime()) {
        window.location = '/login';
      } else {
        this.load();
      }
    }, 600);
  }

  load = () => {
    const xhr = this.createCORSRequest('GET', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/items');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({
          ingredients: body.items.map((item) => {
            const segment = item.name.split(' ');
            console.log('test123', segment);
            return {
              title: item.name,
              id: item.item_id,
              break: segment.length > 1 ? segment[0] : '',
              newline: segment.length > 1 ? segment.slice(1, segment.length).join(' ') : ''
            };
          }).sort((a, b) => {
            return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
          })
        });
      } else {
        console.log('e', body.message);
      }
      this.props.onComplete();
    };

    xhr.send();
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <main>
        <div className="catalog-items__wrap">
          <div className="catalog-items">
            {this.state.ingredients.map((item, i) =>
              (
                <Link className="catalog-item clickable" key={i} to={`/pages/item?d=${item.id}`} >
                  <div className="catalog-item__link">
                    <div className="catalog-item__info">
                      <Row>
                        <Col>
                          <h4 className="catalog-item__title">{item.break ? item.break : item.title}</h4>
                          {item.newline && <h4 className="catalog-item__title">{item.newline}</h4>}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </main>
    );
  }
}
