import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import AWS from 'aws-sdk';
import { notify } from 'react-notify-toast';
import LineForm from './components/LineForm';
import Config from '../Config';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';

AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'ap-southeast-1:71d0e0cb-b942-4f14-99a7-d376d48acbbb'
});

AWS.config.update({
  accessKeyId: 'AKIA3QWFJ5NAZ7337DFY',
  secretAccessKey: '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',
  region: 'ap-southeast-1',
});

const s3 = new AWS.S3({ params: { Bucket: 'signatory' } });

class ReceiptPage extends Component {
  static propTypes = {
    updateConfig: PropTypes.func,
    config: PropTypes.object,
    updateBasket: PropTypes.func,
    basket: PropTypes.array,
    menu: PropTypes.array,
  };

  state = {
    error: '',
    loading: true,
    data: {
      line1: '',
      line2: '',
      line3: '',
      line4: '',
      line5: '',
      logo: ''
    }
  };

  componentDidMount() {
    setTimeout(() => {
      if (this.props.config.user.expiry <= new Date().getTime()) {
        window.location = '/login';
      } else {
        this.load();
      }
    }, 600);
  }

  submit = (e) => {
    console.log('e', e);
    this.setState({
      loading: true,
      data: {
        line1: e.line1,
        line2: e.line2,
        line3: e.line3,
        line4: e.line4,
        line5: e.line5,
        logo: e.logo,
      }
    }, () => {
      this.setState({ error: '' });

      const params = {
        data: {
          line1: this.state.data.line1,
          line2: this.state.data.line2,
          line3: this.state.data.line3,
          line4: this.state.data.line4,
          line5: this.state.data.line5,
          logo: this.state.data.logo,
        }
      };
      console.log('p', params);
      const xhr = this.createCORSRequest('POST', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/receipt/update');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('x-api-key', Config.API_KEY);
      xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

      xhr.onload = () => {
        const data = JSON.parse(xhr.responseText);
        const body = JSON.parse(data.body);
        if (data.statusCode === 200) {
          this.setState({ loading: false }, () => {
            notify.show('Updated successfully', 'custom', 5000, {
              background: '#343a40',
              text: '#c88ffa'
            });
          });
        } else {
          console.log('e-item', body);
          this.setState({ loading: false, error: body.message });
        }
      };
      
      xhr.send(JSON.stringify(params));
    });
  }

  createLogo(file) {
    const key = `logo_${new Date().getTime()}.${file.type.split('/')[1]}`;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const bin = reader.result;
      console.log(reader.result);
      const regex = /^data:image\/\w+;base64,/;
      const buf = new Buffer(bin.replace(regex, ''), 'base64');
      const data = {
        Key: key,
        Body: buf,
        ContentEncoding: 'base64',
        ContentType: file.type,
        ACL: 'public-read'
      };
      s3.putObject(data, (err, d) => {
        if (err) {
          console.log('err1', err);
          //this.setState({ error: err });
        } else {
          this.setState({
            ...data,
            logo: 'https://signatory.s3-ap-southeast-1.amazonaws.com/' + key
          });
        }
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  load = () => {
    const xhr = this.createCORSRequest('POST', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/receipt');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({
          data: body.item.header
        });
      } else {
        console.log('e', body.message);
        this.setState({ error: body.message });
      }
      this.setState({ loading: false });
    };

    xhr.send();
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#c88ffa"
        spinnerType="ball-clip-rotate"
        textColor="#c88ffa"
        logoSrc={Logo}
      >
        <Container>
          <Alert color="danger" className="bold-text" isOpen={this.state.error}>
            <div className="alert__content">
              {this.state.error}
            </div>
          </Alert>

          <Row>
            <Col md={12}>
              <h3 className="page-title">Receipt Header</h3>
              <h3 className="page-subhead subhead">Modify the header of invoice printing
              </h3>
            </Col>
          </Row>
          
          <Row>
            <Col md={12} hidden={true}>
              <Card>
                <CardBody>
                  <h3>Select Logo File</h3>
                  <br />
                  <input
                    type="file"
                    id="input"
                    onChange={
                      (e) => {
                        e.preventDefault();
                        const files = [...e.target.files];
                        this.createLogo(files[0]);
                      }
                    } />
                </CardBody>
              </Card>
            </Col>

            <Col md={12}>
              <Card>
                <CardBody>
                  <LineForm initialValues={this.state.data} onSubmit={this.submit} isDisabled={this.state.loading} enableReinitialize />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default ReceiptPage;
