/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EditTable from '../../EditableTable';

const MoneyFormatter = ({ value }) => (
  <div>
      ${parseFloat(value).toFixed(2)}
  </div>);

MoneyFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const StatusFormatter = ({ value }) => (
  value ? <span className="badge badge-success">Submitted</span> :
  <span className="badge badge-disabled">Please Wait</span>
);

StatusFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

export default class LoadTable extends Component {
  static propTypes = {
    data: PropTypes.array
  };

  constructor() {
    super();
    this.heads = [
      {
        key: 'po',
        name: 'PO Number',
        sortable: true,
      },
      {
        key: 'date',
        name: 'Delivery Date',
        sortable: true,
      },
      {
        key: 'customer',
        name: 'Customer Name',
        sortable: true,
      },
      {
        key: 'address',
        name: 'Address',
        sortable: false,
      },
      {
        key: 'name',
        name: 'Item Name',
        sortable: true
      },
      {
        key: 'price',
        name: 'Unit Price',
        sortable: false,
        formatter: MoneyFormatter,
      },
      {
        key: 'qty',
        name: 'Qty',
        sortable: false,
      },
      {
        key: 'subtotal',
        name: 'Subtotal',
        sortable: false,
        formatter: MoneyFormatter,
      },
      {
        key: 'submitted',
        name: 'Status',
        sortable: true,
        formatter: StatusFormatter,
      }
    ];

    this.state = {
      pageOfItems: [],
      data: []
    };
  }
  
  componentWillReceiveProps(newProps) {
    console.log('props', newProps);
    this.setState({ data: newProps.data });  
  }

  onChangePage = (pageOfItems) => {
    // update state with new page of items
    this.setState({ pageOfItems });
  };

  onSelectSize = (e) => {
    this.setState({
      pageSize: parseInt(e.target.value, 10)
    });
  }
  
  render() {
    return (
      <Col md={12} lg={12}>
        {this.props.data.length > 0
          && <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Orders list</h5>
              </div>
              <EditTable heads={this.heads} rows={this.state.data} />
            </CardBody>
          </Card>
        }
      </Col>
    );
  }
}
