import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Row, Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import ContactIcon from 'mdi-react/PhoneIcon';
import AddressIcon from 'mdi-react/AddressMarkerIcon';
import UserIcon from 'mdi-react/UserIcon';
import PriceIcon from 'mdi-react/MoneyIcon';
import TimetableIcon from 'mdi-react/TimetableIcon';
import { withRouter } from 'react-router-dom';
import Config from '../Config';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';

class CheckoutPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    basket: PropTypes.array,
    updateBasket: PropTypes.func,
    history: PropTypes.object
  };

  state = {
    loading: false,
    editablePrice: false,
    error: ''
  };

  price = 0;

  calcPrice() {
    this.price = 0;
    for (let i = 0; i < this.props.basket.length; i++) {
      if (typeof this.props.basket[i].quantity !== 'number') {
        this.props.basket[i].quantity = parseFloat(this.props.basket[i].quantity);
      }
      this.price += this.props.basket[i].price * this.props.basket[i].quantity;
    }
    return `$${(this.price / 100).toFixed(2)}`;
  }

  reset = () => {
    this.nameRef.value = '';
    this.contactRef.value = '';
    this.addressRef.value = '';
    this.noteRef.value = '';
  }

  submit = () => {
    if (this.timeRef.value === '') return;

    this.setState({ loading: true });

    const params = {
      branch_id: this.props.config.user.branches[0].branch_id,
      timestamp: new Date(this.timeRef.value).getTime(),
      items: this.props.basket.map((item) => {
        return {
          id: item.id,
          name: item.title,
          qty: item.quantity,
          price: item.price,
          unit: item.unit
        };
      }),
      price: this.price,
      details: {
        name: this.nameRef.value === '' ? 'None' : this.nameRef.value,
        contact: this.contactRef.value === '' ? 'None' : this.contactRef.value,
        address: this.addressRef.value === '' ? 'None' : this.addressRef.value,
        note: this.noteRef.value === '' ? 'None' : this.noteRef.value
      }
    };

    const xhr = this.createCORSRequest('POST', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/delivery/create');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      console.log('e', xhr.responseText);
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.props.updateBasket([]);
        this.setState({ loading: false }, () => {
          this.props.history.push('/pages/order-confirm');
        });
      } else {
        this.setState({ loading: false, error: body.message });
        console.log('e', body.message);
      }
    };

    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <Container className="dashboard">
          <Alert color="danger" className="bold-text" isOpen={this.state.error}>
            <div className="alert__content">
              {this.state.error}
            </div>
          </Alert>
          <Row>
            <Col md={12}>
              <h3 className="page-title">Checkout for Delivery</h3>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Card>
                <CardBody>
                  {this.props.basket.map((item) => (
                    <div className="checkoutlist__collapse-item" key={item.id}>
                      <p className="checkoutlist__collapse-name">{item.title}</p>
                      <p className="checkoutlist__collapse-date">x{item.quantity} {item.unit}</p>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Order Note</h5>
                    <h5 className="subhead">Enter additional information for delivery</h5>
                  </div>
                  <form className="form">
                    <div className="form__form-group">
                      <span className="form__form-group-label">Total Price</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <PriceIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="text"
                          ref={(ref) => { this.priceRef = ref; }}
                          value={this.calcPrice()}
                          disabled={!this.state.editablePrice}
                          placeholder="Total Price"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Name</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <UserIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="text"
                          ref={(ref) => { this.nameRef = ref; }}
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Contact Number</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <ContactIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="tel"
                          ref={(ref) => { this.contactRef = ref; }}
                          placeholder="Contact Number"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Address</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <AddressIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="text"
                          ref={(ref) => { this.addressRef = ref; }}
                          placeholder="Address"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Delivery Date & Time</span>
                      <div className="form__form-group-field">
                        <input
                          name="date_time"
                          component="input"
                          type="datetime-local"
                          ref={(ref) => { this.timeRef = ref; }}
                          placeholder="Collection Time"
                        />
                        <div className="form__form-group-icon">
                          <TimetableIcon />
                        </div>
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Notes</span>
                      <div className="form__form-group-field">
                        <textarea
                          name="textarea"
                          type="text"
                          rows="10"
                          ref={(ref) => { this.noteRef = ref; }}
                          placeholder="Notes (optional)"
                        />
                      </div>
                    </div>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="primary" onClick={() => this.submit()} disabled={this.state.loading}>Submit</Button>
                      <Button type="button" onClick={() => this.reset()} disabled={this.state.loading}>Reset</Button>
                    </ButtonToolbar>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}
    
export default withRouter(CheckoutPage);
