import React, { Component } from 'react';
import { Card, CardBody, Col, Container, Row, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import readXlsxFile from 'read-excel-file';
import { notify } from 'react-notify-toast';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';
import Config from '../Config';
import LoadTable from './components/LoadTable';

class LoadFilePage extends Component {
  static propTypes = {
    updateConfig: PropTypes.func,
    config: PropTypes.object,
    updateBasket: PropTypes.func,
    basket: PropTypes.array,
    menu: PropTypes.array,
  };

  constructor() {
    super();
    this.complete = this.complete.bind(this);
  }

  state = {
    loading: true,
    error: '',
    items: [],
    pos: [],
    table: []
  };

  componentDidMount() {
    /*notify.show('Loading, please wait', 'custom', 5000, {
      background: '#343a40',
      text: '#ddae71'
    });*/

    setTimeout(() => {
      if (this.props.config.user.expiry <= new Date().getTime()) {
        window.location = '/login';
      } else {
        this.load();
      }
    }, 600);
  }

  onFileChange = (file, name) => {
    readXlsxFile(file, {
      'DELIVERY DATE': {
        prop: 'date',
        type: String
      }
    }).then((rows) => {
      //console.log('testx', this.state.items, rows);
      rows.shift();
      let pos = [...new Set(rows.map(row => row[0].toString()))];
      pos = pos.map(po => {
        const r = rows.filter(row => row[0].toString() === po);
        let price = 0;
        return {
          po: po,
          branch_id: this.props.config.user.branches[0].branch_id,
          timestamp: r[0][4].getTime(),
          items: r.map(row => {
            const f = this.state.items.filter(item => item.id === row[18].toString() || item.title.indexOf(row[18].toString()) > -1);

            price += f.length > 0 ? (f[0].price * row[26]) : (row[28] * 100);

            return f.length > 0 ? {
              id: f[0].id,
              name: f[0].title,
              qty: row[26],
              price: f[0].price
            } : {
              id: row[18].toString(),
              name: row[20],
              qty: row[26],
              price: row[27] * 100
            };
          }),
          price: price,
          details: {
            name: r[0][8],
            contact: 'None',
            address: `${r[0][9]} ${r[0][12]} ${r[0][14]}`,
            note: 'None'
          }
        };
      });

      console.log('testz', pos);

      const table = [];
      for (let i = 0; i < pos.length; i++) {
        for (let j = 0; j < pos[i].items.length; j++) {
          table.push({
            id: pos[i].items[j].id,
            name: pos[i].items[j].name,
            qty: pos[i].items[j].qty,
            price: (pos[i].items[j].price / 100),
            subtotal: ((pos[i].items[j].price * pos[i].items[j].qty) / 100),
            po: pos[i].po,
            customer: pos[i].details.name,
            address: pos[i].details.address,
            date: pos[i].timestamp,
            submitted: false
          });
        }
      }

      console.log('test b', pos.map(it => it.po));

      this.setState({
        pos: pos,
        table: table
      }, () => this.check());

      console.log('testx', pos);
    });
  }

  load = () => {
    const xhr = this.createCORSRequest('GET', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/items');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({
          items: body.items.map((item) => {
            return {
              title: item.name,
              id: item.item_id,
              price: item.price,
              unit: item.unit
            };
          }).sort((a, b) => {
            return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
          })
        });
      } else {
        console.log('e', body.message);
      }
      this.setState({ loading: false });
    };

    xhr.send();
  }

  check = () => {
    if (this.state.pos.length === 0) {
      notify.show('Please load a valid Excel sheet', 'custom', 5000, {
        background: '#343a40',
        text: '#ddae71'
      });
      return;
    }

    const table = this.state.table;
    this.setState({ loading: true, table: [] });
    let completion = 0;
    const accepted = [];

    this.state.pos.forEach(params => {
      const xhr = this.createCORSRequest('POST', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/po');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('x-api-key', Config.API_KEY);
      xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

      xhr.onload = () => {
        //console.log('e', xhr.responseText);
        const data = JSON.parse(xhr.responseText);
        const body = JSON.parse(data.body);
        if (data.statusCode === 200) {
          if (body.po.length === 0) {
            params.src = 'excel';
            accepted.push(params);
          }
        } else {
          console.log('testerr-po', body.message);
        }

        completion += 1;

        if (completion === this.state.pos.length) {
          console.log('test-it', this.state.items);
          this.setState({ 
            loading: false,
            pos: accepted,
            table: table.filter(item => accepted.filter(ac => ac.po === item.po).length > 0)
          });
        }
      };

      xhr.send(JSON.stringify({
        po: params.po
      }));
    });
  }

  submit = () => {
    if (this.state.pos.length === 0) {
      notify.show('Please load a valid Excel sheet', 'custom', 5000, {
        background: '#343a40',
        text: '#ddae71'
      });
      return;
    }

    this.setState({ loading: true });
    let completion = 0;
    let success = 0;
    let failed = 0;

    this.state.pos.forEach(params => {
      const xhr = this.createCORSRequest('POST', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/delivery/create');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('x-api-key', Config.API_KEY);
      xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

      xhr.onload = () => {
        //console.log('e', xhr.responseText);
        const data = JSON.parse(xhr.responseText);
        const body = JSON.parse(data.body);
        if (data.statusCode === 200) {
          const table = this.state.table;

          for (let i = 0; i < table.length; i++) {
            if (table[i].po === params.po) {
              table[i].submitted = true;
            }
          }

          success += 1;
          console.log('test-stats', `F:${failed}, S:${success}, T:${completion}`);

          this.setState({ table: table });
        } else {
          this.setState({ error: body.message });
          console.log('e', body.message);

          failed += 1;
          console.log('test-stats', `F:${failed}, S:${success}, T:${completion}`);

          notify.show(`Error in PO ${params.po}`, 'custom', 3000, {
            background: '#343a40',
            text: '#ddae71'
          });
        }

        completion += 1;

        if (completion === this.state.pos.length) {
          console.log('test-stats', `F:${failed}, S:${success}, T:${completion}`);

          this.setState({ 
            loading: false,
            items: [],
            pos: [],
            table: []
          });

          notify.show('Successfully created the orders', 'custom', 5000, {
            background: '#343a40',
            text: '#ddae71'
          });
        }
      };

      xhr.send(JSON.stringify(params));
    });
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  complete() {
    this.setState({
      loading: false
    });
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#c88ffa"
        spinnerType="ball-clip-rotate"
        textColor="#c88ffa"
        logoSrc={Logo}
      >
        <Container>
          <Row>
            <Col md={10}>
              <h3 className="page-title">Load from Excel</h3>
              <h3 className="page-subhead subhead">Please make sure all the items in excel sheet has been created in the system, else unrecognized items will be ignored
              </h3>
            </Col>
            <Col md={2}>
              <Button className="btn btn-success" onClick={this.submit} hidden={this.loading}>Submit</Button>
            </Col>
          </Row>
          
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <h3>Select Excel File</h3>
                  <br />
                  <input
                    type="file"
                    id="input"
                    onChange={
                      (e) => {
                        e.preventDefault();
                        const files = [...e.target.files];
                        this.onFileChange(files[0], files[0].name);
                      }
                    } />
                </CardBody>
              </Card>
            </Col>

            <Col md={12}>
              <LoadTable data={this.state.table} />
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default LoadFilePage;
