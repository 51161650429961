import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Row, Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import ContactIcon from 'mdi-react/PhoneIcon';
import AddressIcon from 'mdi-react/AddressMarkerIcon';
import UserIcon from 'mdi-react/UserIcon';
import PriceIcon from 'mdi-react/MoneyIcon';
import TimetableIcon from 'mdi-react/TimetableIcon';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import Config from '../Config';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';

class CategoryCheckoutPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    basket: PropTypes.array,
    updateBasket: PropTypes.func,
    history: PropTypes.object,
    location: PropTypes.object
  };

  state = {
    loading: false,
    editablePrice: false,
    price: '',
    error: '',
    items: [],
    details: [],
    name: '',
    selected_id: '',
    selected_name: '',
    selected_contact: '',
    selected_address: '',
    selected_details: {},
    note: ''
  };

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);
    this.load(queries.id);
  }

  onSelectName = (e) => {
    const details = this.state.details;
    const selection = details.filter(item => item.id === e.target.value)[0];
    this.setState({
      selected_details: selection,
      selected_id: selection.id,
      selected_name: selection.name,
      selected_address: selection.address,
      selected_contact: selection.contact
    });
  }

  calcPrice() {
    let price = 0;
    for (let i = 0; i < this.state.items.length; i++) {
      price += this.state.items[i].price * this.state.items[i].quantity;
    }
    this.setState({ price: `$${(price / 100).toFixed(2)}` });
    return price;
  }

  updateQuantity = (e, i) => {
    this.setState({
      items: this.state.items.map((item, index) => {
        const edit = item;
        if (index === i) {
          edit.quantity = e.target.value === '' ? 0 : parseInt(e.target.value, 10);
        }
        return edit;
      })
    }, () => this.calcPrice());
  }

  load = (id) => {
    this.setState({ loading: true });

    const params = {
      category_id: id
    };

    console.log('test post', params);

    const xhr = this.createCORSRequest('POST', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/category/get');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      console.log('testb', body);
      if (data.statusCode === 200) {
        this.setState({
          name: body.category.name,
          details: body.category.details,
          items: body.category.items.map(item => {
            const i = item;
            i.quantity = 0;
            return i;
          }),
          loading: false
        });
      } else {
        console.log('e', body);
        this.setState({ loading: false, error: body.message });
      }
    };
    
    xhr.send(JSON.stringify(params));
  }

  submit = () => {
    if (this.timeRef.value === '') return;

    this.setState({ loading: true });

    const params = {
      branch_id: this.props.config.user.branches[0].branch_id,
      timestamp: new Date(this.timeRef.value).getTime(),
      items: this.state.items.map((item) => {
        return {
          id: item.item_id,
          name: item.name,
          qty: item.quantity,
          price: item.price
        };
      }).filter(item => item.qty > 0),
      price: this.calcPrice(),
      details: {
        name: this.state.selected_name ? this.state.selected_name : 'None',
        contact: this.state.selected_contact ? this.state.selected_contact : 'None',
        address: this.state.selected_address ? this.state.selected_address : 'None',
        note: this.state.note ? this.state.note : 'None'
      }
    };

    if (this.state.selected_details.line1) {
      params.details.line1 = this.state.selected_details.line1;

      if (this.state.selected_details.line2) {
        params.details.line2 = this.state.selected_details.line2;

        if (this.state.selected_details.line3) {
          params.details.line3 = this.state.selected_details.line3;
        }
      }
    }

    const xhr = this.createCORSRequest('POST', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/delivery/create');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      console.log('e', xhr.responseText);
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.props.updateBasket([]);
        this.setState({ loading: false }, () => {
          this.props.history.push('/pages/order-confirm');
        });
      } else {
        this.setState({ loading: false, error: body.message });
        console.log('e', body.message);
      }
    };

    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <Container className="dashboard">
          <Alert color="danger" className="bold-text" isOpen={this.state.error}>
            <div className="alert__content">
              {this.state.error}
            </div>
          </Alert>
          <Row>
            <Col md={12}>
              <h3 className="page-title">Checkout for Delivery</h3>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Card>
                <CardBody>
                  {this.state.items.map((item, i) => (
                    <div className="catalog-item" key={item.item_id}>
                      {item.name}
                      <input
                        name="name"
                        component="input"
                        type="text"
                        placeholder="Quantity"
                        value={item.quantity > 0 ? item.quantity : '' }
                        onChange={e => this.updateQuantity(e, i)}
                        required
                      />
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Order Note</h5>
                    <h5 className="subhead">Enter additional information for delivery</h5>
                  </div>
                  <form className="form">
                    <div className="form__form-group">
                      <span className="form__form-group-label">Total Price</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <PriceIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="text"
                          value={this.state.price}
                          disabled={!this.state.editablePrice}
                          placeholder="Total Price"
                        />
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">Name</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <UserIcon />
                        </div>
                        <select className="form__form-group-select" value={this.state.selected_id} onChange={this.onSelectName}>
                          <option disabled defaultValue value="">Select Name</option>
                          {
                            this.state.details.map((item) => (
                              <option value={item.id}>{item.name}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>

                    <div className="form__form-group">
                      <span className="form__form-group-label">Contact Number</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <ContactIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="tel"
                          disabled={true}
                          value={this.state.selected_contact}
                          placeholder="Contact Number"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Address</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <AddressIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="text"
                          disabled={true}
                          value={this.state.selected_address}
                          placeholder="Address"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Delivery Date & Time</span>
                      <div className="form__form-group-field">
                        <input
                          name="date_time"
                          component="input"
                          type="datetime-local"
                          ref={(ref) => { this.timeRef = ref; }}
                          placeholder="Collection Time"
                        />
                        <div className="form__form-group-icon">
                          <TimetableIcon />
                        </div>
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Notes</span>
                      <div className="form__form-group-field">
                        <textarea
                          name="textarea"
                          type="text"
                          rows="10"
                          value={this.state.note}
                          onChange={e => this.setState({ note: e.target.value })}
                          placeholder="Notes (optional)"
                        />
                      </div>
                    </div>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="primary" onClick={() => this.submit()} disabled={this.state.loading}>Submit</Button>
                    </ButtonToolbar>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}
    
export default withRouter(CategoryCheckoutPage);
