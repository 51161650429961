import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Layout from '../_layout/index';
import MainWrapper from './MainWrapper';

import LogIn from '../login/index';
import DashboardPage from '../dashboard/index';
import OrderPage from '../submitorder/index';
import CheckoutPage from '../checkout/index';
import OrderConfirmPage from '../orderconfirm';
import ViewOrderPage from '../vieworder';
import ItemsPage from '../items';
import NewItemPage from '../item_add';
import ItemPage from '../item';
import ElementsPage from '../elements';
import ElementPage from '../element';
import Register from '../register';
import LoadFilePage from '../loadfile/index';
import ReceiptPage from '../receipt';
import CategoriesPage from '../categories';
import CategoryPage from '../category';
import CategoryCheckoutPage from '../categorycheckout';

class Pages extends Component {
  static propTypes = {
    updateConfig: PropTypes.func,
    config: PropTypes.object,
    updateBasket: PropTypes.func,
    basket: PropTypes.array,
    notification: PropTypes.array,
    removeNotification: PropTypes.func,
    token: PropTypes.string
  };
  
  render() {
    return (
      <Switch>
        <Route path="/pages/dashboard" render={(routeProps) => <DashboardPage config={this.props.config} updateConfig={this.props.updateConfig} token={this.props.token} location={routeProps.location} />} />
        <Route path="/pages/elements" render={(routeProps) => <ElementsPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/categories" render={(routeProps) => <CategoriesPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/category" render={(routeProps) => <CategoryPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/element" render={(routeProps) => <ElementPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/view" render={(routeProps) => <ViewOrderPage config={this.props.config} updateConfig={this.props.updateConfig} location={routeProps.location} />} />
        <Route path="/pages/items" render={(routeProps) => <ItemsPage config={this.props.config} updateConfig={this.props.updateConfig} location={routeProps.location} />} />
        <Route path="/pages/newitem" render={(routeProps) => <NewItemPage config={this.props.config} updateConfig={this.props.updateConfig} location={routeProps.location} />} />
        <Route path="/pages/item" render={(routeProps) => <ItemPage config={this.props.config} location={routeProps.location} />} />
        <Route path="/pages/order" render={(routeProps) => <OrderPage config={this.props.config} updateConfig={this.props.updateConfig} basket={this.props.basket} updateBasket={this.props.updateBasket} location={routeProps.location} />} />
        <Route path="/pages/checkout" render={(routeProps) => <CheckoutPage config={this.props.config} updateConfig={this.props.updateConfig} basket={this.props.basket} updateBasket={this.props.updateBasket} location={routeProps.location} />} />
        <Route path="/pages/corder" render={(routeProps) => <CategoryCheckoutPage config={this.props.config} updateConfig={this.props.updateConfig} basket={this.props.basket} updateBasket={this.props.updateBasket} location={routeProps.location} />} />
        <Route path="/pages/load" render={(routeProps) => <LoadFilePage config={this.props.config} updateConfig={this.props.updateConfig} basket={this.props.basket} updateBasket={this.props.updateBasket} location={routeProps.location} />} />
        <Route path="/pages/receipt" render={(routeProps) => <ReceiptPage config={this.props.config} updateConfig={this.props.updateConfig} basket={this.props.basket} updateBasket={this.props.updateBasket} location={routeProps.location} />} />
        <Route path="/pages/order-confirm" component={OrderConfirmPage} />
      </Switch>
    );
  }
}

class WrappedRoutes extends Component {
  static propTypes = {
    config: PropTypes.object,
    updateBasket: PropTypes.func,
    basket: PropTypes.array,
    notification: PropTypes.array,
    removeNotification: PropTypes.func,
    token: PropTypes.string
  };

  render() {
    return (
      <div>
        <Layout config={this.props.config} basket={this.props.basket} updateBasket={this.props.updateBasket} notification={this.props.notification} removeNotification={this.props.removeNotification} />
        <div className="container__wrap">
          <Route path="/pages" render={(routeProps) => <Pages config={this.props.config} token={this.props.token} basket={this.props.basket} updateBasket={this.props.updateBasket} notification={this.props.notification} removeNotification={this.props.removeNotification} location={routeProps.location} />} />
        </div>
      </div>
    );
  }
}

class Router extends Component {
  render() {
    return (
      <MainWrapper>
        <main>
          <Switch>
            <Route exact path="/" render={(routeProps) => <LogIn update={this.props.updateConfig} config={this.props.config} location={routeProps.location} />} />
            <Route exact path="/login" render={(routeProps) => <LogIn update={this.props.updateConfig} config={this.props.config} location={routeProps.location} />} />
            <Route exact path="/logout" render={(routeProps) => <LogIn update={this.props.updateConfig} config={this.props.config} location={routeProps.location} />} />
            <Route exact path="/register" render={(routeProps) => <Register update={this.props.updateConfig} config={this.props.config} location={routeProps.location} />} />
            <Route path="/" render={(routeProps) => <WrappedRoutes config={this.props.config} token={this.props.token} basket={this.props.basket} updateBasket={this.props.updateBasket} notification={this.props.notification} removeNotification={this.props.removeNotification} location={routeProps.location} />} />
          </Switch>
        </main>
      </MainWrapper>
    );
  }
}

Router.propTypes = {
  updateConfig: PropTypes.func,
  config: PropTypes.object,
  updateBasket: PropTypes.func,
  basket: PropTypes.array,
  notification: PropTypes.array,
  removeNotification: PropTypes.func,
  token: PropTypes.string
};

export default Router;
