import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Col, Row, Button, ButtonToolbar } from 'reactstrap';
import ContactIcon from 'mdi-react/ScaleBalanceIcon';
import PropTypes from 'prop-types';
import UserIcon from 'mdi-react/UserIcon';
import PriceIcon from 'mdi-react/CashIcon';

const IngredientForm = ({ handleChange, handleSubmit, value, isDisabled }) => (
  <form className="form" onSubmit={handleSubmit}>
    
    <div className="form__form-group">
      <span className="form__form-group-label">Name</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <UserIcon />
        </div>
        <Field
          name="name"
          component="input"
          type="text"
          placeholder="Name"
          disabled={isDisabled}
          required
        />
      </div>
    </div>
    
    <div className="form__form-group">
      <span className="form__form-group-label">Unit</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <ContactIcon />
        </div>
        <Field
          name="unit"
          component="input"
          type="text"
          placeholder="Unit"
        />
      </div>
    </div>
    
    <div className="form__form-group">
      <span className="form__form-group-label">Price</span>
      <div className="form__form-group-field">
        <div className="form__form-group-icon">
          <PriceIcon />
        </div>
        <Field
          name="price"
          component="input"
          type="number"
          placeholder="Price in $"
          disabled={isDisabled}
        />
      </div>
    </div>
    <div className="form__form-group">
      <span className="form__form-group-label">Notes</span>
      <div className="form__form-group-field">
        <Field
          name="note"
          component="textarea"
          type="text"
          rows="10"
          placeholder="Notes (optional)"
          disabled={isDisabled}
        />
      </div>
    </div>
    <ButtonToolbar className="form__button-toolbar">
      <Button color="primary" type="submit" disabled={isDisabled}>Submit</Button>
    </ButtonToolbar>
  </form>
);

IngredientForm.propTypes = {
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  value: PropTypes.object,
  isDisabled: PropTypes.bool
};
  
export default reduxForm({ form: 'ingredientform' })(IngredientForm);
