/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import QtyIcon from 'mdi-react/CartPlusIcon';
import Config from '../../Config';

export default class Catalog extends Component {
  static propTypes = {
    config: PropTypes.object,
    basket: PropTypes.array,
    update: PropTypes.func,
    complete: PropTypes.func
  };

  state = {
    menu: []
  };

  componentDidMount() {
    setTimeout(() => {
      this.load();
    }, 600);
  }

  filterNum = (str) => {
    const numericalChar = new Set(['.', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);
    str = str.split('').filter(char => numericalChar.has(char)).join('');
    return str;
  }

  load = () => {
    const xhr = this.createCORSRequest('GET', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/items');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        const menu = body.items.map((item) => ({
          id: item.item_id,
          price: item.price,
          title: item.name,
          quantity: 0,
          unit: item.unit ? item.unit : ''
        })).sort((a, b) => {
          return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
        });

        this.setState({
          menu: menu
        }, () => {
          this.props.complete();
        });
      } else {
        console.log('e', body.message);
      }
    };

    xhr.send();
  }

  updateQuantity = (e, i) => {
    this.setState({
      menu: this.state.menu.map((item, index) => {
        const edit = item;
        if (index === i) {
          if (!edit.quantity) edit.quantity = '';
          edit.quantity = this.filterNum(e.target.value);
        }
        return edit;
      })
    }, () => {
      this.props.update(this.state.menu.filter((item, index) => {
        return this.state.menu[index].quantity > 0;
      }));
    });
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <main>
        <div className="catalog-items__wrap">
          <div className="catalog-items">
            {this.state.menu.map((item, i) =>
              (
                <div className="catalog-item" key={i}>
                  <div className="catalog-item__link cursor">
                    <div className="catalog-item__info">
                      <Row>
                        <Col md={9}>
                          <h4 className="catalog-item__title">{item.title}</h4>
                          <br />
                          <form className="form">
                            <div className="form__form-group">
                              <div className="form__form-group-field">
                                <div className="form__form-group-icon">
                                  <QtyIcon />
                                </div>
                                <input
                                  name="name"
                                  component="input"
                                  type="text"
                                  placeholder="Quantity"
                                  value={this.state.menu[i].quantity ? this.state.menu[i].quantity : '' }
                                  onChange={e => this.updateQuantity(e, i)}
                                  required
                                />
                              </div>
                            </div>
                          </form>
                        </Col>
                        <Col md={3}>
                          <h1 className="catalog-item__price">${(item.price / 100).toFixed(2)}</h1>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </main>
    );
  }
}
