import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { notify } from 'react-notify-toast';
import { Link } from 'react-router-dom';
import Config from '../Config';
import IngredientForm from './components/IngredientForm';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';

class ItemPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    location: PropTypes.object
  };

  state = {
    loading: true,
    item_id: '',
    name: '',
    note: '',
    unit: '',
    error: ''
  };

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);
    this.load(queries.d);
    this.submit = this.submit.bind(this);
    this.edit = this.edit.bind(this);
  }

  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  load = (inventoryId) => {
    this.setState({
      item_id: inventoryId
    });

    const params = {
      item_id: inventoryId
    };

    const xhr = this.createCORSRequest('POST', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/item/get');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);
    
    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({
          data: {
            name: body.item.name,
            note: body.item.note,
            price: (body.item.price / 100).toFixed(2),
            unit: body.item.unit
          }
        });
      } else {
        this.setState({ error: body.message });
        console.log('e', body.message);
      }
      this.setState({ loading: false });
    };

    xhr.send(JSON.stringify(params));
  }

  update = (notice) => {
    this.setState({ error: '' });

    const params = {
      item_id: this.state.item_id,
      data: {
        name: this.state.data.name,
        unit: this.state.data.unit,
        price: this.state.data.price * 100,
        note: this.state.data.note
      }
    };
    console.log('p', params);
    const xhr = this.createCORSRequest('POST', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/item/update');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({ loading: false }, () => {
          if (notice) {
            notify.show('Item updated successfully', 'custom', 5000, {
              background: '#343a40',
              text: '#c88ffa'
            });
          }
        });
      } else {
        console.log('e-item', body);
        this.setState({ loading: false, error: body.message });
      }
    };
    
    xhr.send(JSON.stringify(params));
  }
  
  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  edit = (e) => {
    e.preventDefault();
    this.setState({ qtyDisabled: false });
  }

  submit = (e) => {
    this.setState({
      loading: true,
      data: {
        name: e.name,
        unit: e.unit ? e.unit : 'ea',
        price: e.price,
        note: e.note ? e.note : 'None'
      }
    }, () => {
      this.update(true);
    });
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#c88ffa"
        spinnerType="ball-clip-rotate"
        textColor="#c88ffa"
        logoSrc={Logo}
      >
        <Container className="dashboard">
          <Alert color="danger" className="bold-text" isOpen={this.state.error}>
            <div className="alert__content">
              {this.state.error}
            </div>
          </Alert>
          <Row hidden={this.state.loading}>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Details</h5>
                    <h5 className="subhead">Additional information for item</h5>
                  </div>
                  <IngredientForm initialValues={this.state.data} onSubmit={this.submit} isDisabled={this.state.loading} enableReinitialize />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}
    
export default ItemPage;
