import React, { Component } from 'react';
import { Alert, Button, ButtonToolbar, Card, CardBody, Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { notify } from 'react-notify-toast';
import { Link, withRouter } from 'react-router-dom';
import UserIcon from 'mdi-react/UserIcon';
import TrashIcon from 'mdi-react/TrashIcon';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Config from '../Config';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';
import DragList from './components/DragList';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class CategoryPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object
  };
  
  state = {
    loading: false,
    error: '',
    isNew: true,
    items: [],
    details: [],
    name: '',
    id: '',
    input_name: '',
    input_address: '',
    input_contact: '',
    input_line1: '',
    input_line2: '',
    input_line3: '',
    selected_items: [],
    selected_details_items: [],
    detail_selected: false,
    selected_detail_name: ''
  };
  
  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);
    if (queries.id) {
      this.load(queries.id);
    } else {
      this.state.isNew = true;
      this.loadItems();
    }
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.selected_details_items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      selected_details_items: items,
      details: this.state.details.map(item => {
        if (item.selected) {
          item.items = items;
        }
        return item;
      })
    });
  }

  dragItem = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items: items
    });
  }

  load = (id) => {
    this.setState({
      loading: true,
      error: ''
    });

    const params = {
      category_id: id
    };

    console.log('test post', params);

    const xhr = this.createCORSRequest('POST', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/category/get');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      console.log('testb', body);
      if (data.statusCode === 200) {
        this.setState({
          name: body.category.name,
          details: body.category.details.map(detail => {
            if (detail.items) {
              const items = [];
              for (let i = 0; i < detail.items.length; i++) {
                const f = body.category.items.filter(it => it.item_id === detail.items[i]);
                if (f.length > 0) {
                  items.push(f[0]);
                }
              }
              detail.items = items;
            }
            return detail;
          }),
          selected_items: body.category.items,
          id: id,
          isNew: false
        }, () => {
          if (body.category.zero !== null && body.category.zero !== undefined) this.setState({ zero: body.category.zero });
          this.loadItems();
        });
      } else {
        console.log('e', body);
        this.setState({ loading: false, error: body.message });
      }
    };
    
    xhr.send(JSON.stringify(params));
  }

  loadItems = () => {
    this.setState({ loading: true });

    const xhr = this.createCORSRequest('GET', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/items');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        const items = body.items.map(item => {
          const i = item;
          if (this.state.selected_items.filter(it => it.item_id === i.item_id).length > 0) {
            i.selected = true;
          }
          return i;
        }).sort((a, b) => {
          return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
        });

        const d = [];

        for (let i = 0; i < this.state.selected_items.length; i++) {
          const f = items.filter(it => it.item_id === this.state.selected_items[i].item_id);
          if (f.length > 0) {
            d.push(f[0]);
          }
        }

        for (let i = 0; i < items.length; i++) {
          const f = this.state.selected_items.filter(it => it.item_id === items[i].item_id);
          if (f.length === 0) {
            d.push(items[i]);
          }
        }

        this.setState({
          items: d,
          loading: false
        });
      } else {
        console.log('e', body.message);
        this.setState({ loading: false, error: body.message });
      }
    };

    xhr.send();
  }

  toggleSelectItem = (id) => {
    const items = this.state.items.map(item => {
      const i = item;
      if (item.item_id === id) i.selected = item.selected ? false : true;
      return i;
    });

    console.log('test', items);

    this.setState({ items: items });
  }

  addDetail = () => {
    if (this.state.input_name && this.state.input_address && this.state.input_contact) {
      const detail = {
        id: new Date().getTime().toString(),
        name: this.state.input_name,
        address: this.state.input_address,
        contact: this.state.input_contact
      };

      if (this.state.input_line1) {
        detail.line1 = this.state.input_line1;

        if (this.state.input_line2) {
          detail.line2 = this.state.input_line2;

          if (this.state.input_line3) {
            detail.line3 = this.state.input_line3;
          }
        }
      }

      this.setState({ details: [...this.state.details, detail] });
    }
  }

  deleteDetail = (id) => {
    this.setState({ details: this.state.details.filter(detail => detail.id !== id) });
  }

  submit = () => {
    if (!this.state.name) {
      this.setState({ error: 'Name required' });
    } else {
      this.setState({
        loading: true
      });
  
      console.log('test', this.state);
  
      const params = {
        branch_id: this.props.config.user.branches[0].branch_id,
        timestamp: new Date().getTime(),
        name: this.state.name,
        items: this.state.items.filter(item => item.selected).map(item => {
          return {
            item_id: item.item_id,
            name: item.name,
            note: item.note,
            price: item.price,
            unit: item.unit
          };
        }),
        details: this.state.details.map(item => {
          const it = item;
          delete it.selected;
          if (it.items && it.items.length > 0) {
            it.items = it.items.map(i => i.item_id);
          } else {
            delete it.items;
          }
          return it;
        })
      };
  
      if (this.state.id) params.category_id = this.state.id;

      if (this.state.zero !== null && this.state.zero !== undefined) params.zero = this.state.zero;
  
      const xhr = this.createCORSRequest('POST', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/category');
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('x-api-key', Config.API_KEY);
      xhr.setRequestHeader('Authorization', this.props.config.user.idToken);
  
      xhr.onload = () => {
        const data = JSON.parse(xhr.responseText);
        const body = JSON.parse(data.body);
        console.log('testb', body);
        if (data.statusCode === 200) {
          notify.show(this.state.id ? 'Category updated successfully' : 'Category created successfully', 'custom', 5000, {
            background: '#343a40',
            text: '#c88ffa'
          });
  
          this.props.history.push('/pages/categories');
        } else {
          console.log('e', body);
          this.setState({ loading: false, error: body.message });
        }
      };
      
      xhr.send(JSON.stringify(params));
    }
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  updateDetailSelect = (index) => {
    const details = this.state.details;
    details[index].selected = details[index].selected ? false : true;
    this.setState({
      details: details.map((item, i) => {
        if (i !== index) {
          item.selected = false;
        }
        return item;
      })
    }, () => {
      if (!details[index].selected) {
        console.log('test case', 'new');
        this.setState({
          selected_details_items: this.state.items.filter(item => item.selected),
          detail_selected: false
        });
      } else if (details[index].items && details[index].items.length > 0) {
        console.log('test case', 'exists', details[index].items, this.state.selected_items);
        const items = details[index].items;
        for (let i = items.length - 1; i >= 0; i--) {
          const f = this.state.items.filter(item => item.selected).filter(item => item.item_id === items[i].item_id);
          if (f.length === 0) {
            items.splice(i, 1);
          }
        }
        const accepted = this.state.items.filter(item => item.selected);
        for (let i = 0; i < accepted.length; i++) {
          const f = items.filter(item => item.item_id === accepted[i].item_id);
          if (f.length === 0) {
            items.push(accepted[i]);
          }
        }
        this.setState({
          selected_details_items: details[index].items,
          selected_detail_name: details[index].name,
          detail_selected: true
        });
      } else {
        console.log('test case', 'unknown', details[index]);
        this.setState({
          selected_details_items: this.state.items.filter(item => item.selected),
          selected_detail_name: details[index].name,
          detail_selected: true
        });
      }
    });
  }

  toggleZero = () => {
    if (this.state.zero === undefined && this.state.zero === null) {
      this.setState({ zero: true });
    } else {
      this.setState({ zero: !this.state.zero });
    }
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#c88ffa"
        spinnerType="ball-clip-rotate"
        textColor="#c88ffa"
        logoSrc={Logo}>
          
        <Container className="dashboard">
          <Alert color="danger" className="bold-text" isOpen={this.state.error}>
            <div className="alert__content">
              {this.state.error}
            </div>
          </Alert>

          <div className="card__title">
            <h5 className="bold-text">Category</h5>
            <h5 className="subhead">Manage Category</h5>
          </div>

          <Row hidden={this.state.loading}>
            <Col md={4}>
              {this.state.detail_selected && <DragList items={this.state.selected_details_items} drag={this.onDragEnd} isNew={this.state.isNew} submit={this.submit} detail={this.state.selected_detail_name} />}

              {!this.state.detail_selected && <Card>
                <CardBody>
                  {!this.state.isNew && <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" onClick={() => this.submit()}>Update Category</Button>
                  </ButtonToolbar>}

                  <div className="card__title">
                    <h5 className="bold-text">Items</h5>
                    <h5 className="bold-text">(reorder items by dragging)</h5>
                  </div>
                  <DragDropContext onDragEnd={this.dragItem}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}>
                          {this.state.items.map((item, index) => (
                            <Draggable key={item.item_id} draggableId={item.item_id} index={index}>
                              {(p, s) => (
                                <div
                                  className="checkoutlist__collapse-item"
                                  key={item.item_id}
                                  ref={p.innerRef}
                                  {...p.draggableProps}
                                  {...p.dragHandleProps}>
                                  <input type="checkbox" id={item.item_id} checked={item.selected} onChange={() => this.toggleSelectItem(item.item_id)} />
                                  &nbsp;<p className="checkoutlist__collapse-name">{item.name}</p>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </CardBody>
              </Card>}
            </Col>

            <Col md={5}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">List of Options</h5>
                  </div>
                  {this.state.details.map((item, i) => (
                    <div className="checkoutlist__collapse-item" key={item.id}>
                      <Button className={item.selected ? 'btn-xsm btn-danger' : 'btn-xsm btn-success'} onClick={() => this.updateDetailSelect(i)}><p>{item.selected ? 'Unselect' : 'Select'}</p></Button>
                      <Button className="btn-xsm btn-danger" onClick={() => this.deleteDetail(item.id)}><TrashIcon /></Button>
                      <p className="checkoutlist__collapse-name">{item.name}</p>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>

            <Col md={3}>
              <Row>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <form className="form">
                        <div className="form__form-group">
                          <span className="form__form-group-label">Name</span>
                          <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                              <UserIcon />
                            </div>
                            <input
                              name="name"
                              type="text"
                              placeholder="Name"
                              value={this.state.name}
                              onChange={e => this.setState({ name: e.target.value })}
                            />
                          </div>
                        </div>
                      </form>
                      
                      <div className="checkoutlist__collapse-item">
                        <input type="checkbox" checked={this.state.zero} onChange={() => this.toggleZero()} />
                        &nbsp;<p className="checkoutlist__collapse-name">Print items with zero qty</p>
                      </div>

                      <ButtonToolbar className="form__button-toolbar">
                        <Button color="primary" onClick={() => this.submit()}>{this.state.isNew ? 'Create Category' : 'Update Category'}</Button>
                      </ButtonToolbar>
                    </CardBody>
                  </Card>
                </Col>

                <Col md={12}>
                  <Card>
                    <CardBody>
                      <form className="form">
                        <div className="form__form-group">
                          <span className="form__form-group-label">Name</span>
                          <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                              <UserIcon />
                            </div>
                            <input
                              name="name"
                              type="text"
                              placeholder="Name"
                              value={this.state.input_name}
                              onChange={e => this.setState({ input_name: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="form__form-group">
                          <span className="form__form-group-label">Address</span>
                          <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                              <UserIcon />
                            </div>
                            <input
                              name="address"
                              type="text"
                              placeholder="Name"
                              value={this.state.input_address}
                              onChange={e => this.setState({ input_address: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="form__form-group">
                          <span className="form__form-group-label">Contact Number</span>
                          <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                              <UserIcon />
                            </div>
                            <input
                              name="contact"
                              type="text"
                              placeholder="Name"
                              value={this.state.input_contact}
                              onChange={e => this.setState({ input_contact: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="form__form-group">
                          <span className="form__form-group-label">Line 1</span>
                          <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                              <UserIcon />
                            </div>
                            <input
                              name="line1"
                              type="text"
                              placeholder="Name"
                              value={this.state.input_line1}
                              onChange={e => this.setState({ input_line1: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="form__form-group">
                          <span className="form__form-group-label">Line 2</span>
                          <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                              <UserIcon />
                            </div>
                            <input
                              name="line2"
                              type="text"
                              placeholder="Name"
                              value={this.state.input_line2}
                              onChange={e => this.setState({ input_line2: e.target.value })}
                            />
                          </div>
                        </div>

                        <div className="form__form-group">
                          <span className="form__form-group-label">Line 3</span>
                          <div className="form__form-group-field">
                            <div className="form__form-group-icon">
                              <UserIcon />
                            </div>
                            <input
                              name="line3"
                              type="text"
                              placeholder="Name"
                              value={this.state.input_line3}
                              onChange={e => this.setState({ input_line3: e.target.value })}
                            />
                          </div>
                        </div>

                        <ButtonToolbar className="form__button-toolbar">
                          <Button color="primary" onClick={() => this.addDetail()}>Add</Button>
                        </ButtonToolbar>
                      </form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}
        
export default withRouter(CategoryPage);
