import React, { Component } from 'react';
import { Button, ButtonToolbar, Card, CardBody } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

class DragList extends Component {
  render() {
    return (
      <Card>
        <CardBody>
          {!this.props.isNew && <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" onClick={this.props.submit}>Update Category</Button>
          </ButtonToolbar>}

          <div className="card__title">
            <h5 className="bold-text">You may reorder your items for {this.props.detail}</h5>
            <h5 className="bold-text">(reorder items by dragging)</h5>
          </div>

          <DragDropContext onDragEnd={this.props.drag}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  {this.props.items.map((item, index) => (
                    <Draggable key={item.item_id} draggableId={item.item_id} index={index}>
                      {(p, s) => (
                        <div
                          className="checkoutlist__collapse-item"
                          ref={p.innerRef}
                          {...p.draggableProps}
                          {...p.dragHandleProps}>
                          <p className="checkoutlist__collapse-name">{item.name}</p>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </CardBody>
      </Card>
    );
  }
}

export default DragList;
