import React, { Component } from 'react';
import { Alert, Card, CardBody, Col, Container, Row, Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import UserIcon from 'mdi-react/FoodIcon';
import PriceIcon from 'mdi-react/ScaleBalanceIcon';
import CashIcon from 'mdi-react/CashIcon';
import { notify } from 'react-notify-toast';
import Config from '../Config';
import Unit from '../Units';

const parse = (value) => (value === undefined ? undefined : parseInt(value, 10));

class NewItemPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    basket: PropTypes.array,
    updateBasket: PropTypes.func
  };

  state = {
    loading: false,
    unit: 'ea',
    error: ''
  };

  onSelectUnit = (e) => {
    this.setState({
      unit: e.target.value
    });
  }

  reset = () => {
    this.nameRef.value = '';
    this.noteRef.value = '';
    this.priceRef.value = '';
  }

  submit = () => {
    if (this.nameRef.value === '') return;
    if (!this.priceRef.value) return;

    this.setState({ loading: true });

    const params = {
      name: this.nameRef.value,
      unit: this.state.unit,
      price: this.priceRef.value * 100,
      note: this.noteRef.value === '' ? 'None' : this.noteRef.value
    };
    
    const xhr = this.createCORSRequest('POST', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/item/create');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);

    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.reset();

        notify.show('Item created', 'custom', 5000, {
          background: '#343a40',
          text: '#c88ffa'
        });
      } else {
        console.log('e', body.message);
        this.setState({ error: body.message });
      }
      this.setState({ loading: false });
    };

    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <Container className="dashboard">
        <Alert color="danger" className="bold-text" isOpen={this.state.error}>
          <div className="alert__content">
            {this.state.error}
          </div>
        </Alert>
        <Row>
          <Col md={12}>
            <h3 className="page-title">Add New Item</h3>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <div className="card__title">
                  <h5 className="bold-text">Item Details</h5>
                  <h5 className="subhead">Enter additional information for new item</h5>
                </div>

                <form className="form">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Item Name</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <UserIcon />
                      </div>
                      <input
                        name="leftIcon"
                        component="input"
                        type="text"
                        ref={(ref) => { this.nameRef = ref; }}
                        placeholder="Name"
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Unit</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <PriceIcon />
                      </div>
                      <select className="form__form-group-select" onChange={this.onSelectUnit}>
                        {
                          Unit.map((item) => (
                            <option value={item.value}>{item.label}</option>
                          ))
                        }
                      </select>
                    </div>
                  </div>
                  
                  <div className="form__form-group">
                    <span className="form__form-group-label">Price</span>
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <CashIcon />
                      </div>
                      <input
                        name="price"
                        component="input"
                        type="number"
                        placeholder="Price in $"
                        ref={(ref) => { this.priceRef = ref; }}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Notes</span>
                    <div className="form__form-group-field">
                      <textarea
                        name="textarea"
                        type="text"
                        rows="10"
                        ref={(ref) => { this.noteRef = ref; }}
                        placeholder="Notes (optional)"
                      />
                    </div>
                  </div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" onClick={() => this.submit()} disabled={this.state.loading}>Submit</Button>
                    <Button type="button" onClick={() => this.reset()} disabled={this.state.loading}>Reset</Button>
                  </ButtonToolbar>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}
    
export default NewItemPage;
