import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import CatalogItem from './components/CatalogItem';
import Logo from '../../shared/img/logo.png';
import LoadingScreen from '../../shared/components/loading';

class ItemsPage extends Component {
  static propTypes = {
    updateConfig: PropTypes.func,
    config: PropTypes.object,
    updateBasket: PropTypes.func,
    basket: PropTypes.array,
    menu: PropTypes.array,
  };

  constructor() {
    super();
    this.complete = this.complete.bind(this);
  }

  state = {
    loading: true,
    error: ''
  };

  complete() {
    this.setState({
      loading: false
    });
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#c88ffa"
        spinnerType="ball-clip-rotate"
        textColor="#c88ffa"
        logoSrc={Logo}
      >
        <Container>
          <Row>
            <Col md={10}>
              <h3 className="page-title">Items Management</h3>
              <h3 className="page-subhead subhead">Add or remove items from your inventory
              </h3>
            </Col>
            <Col md={2}>
              <Link className="btn btn-success" to="/pages/newitem">Add Item</Link>
            </Col>
          </Row>
          <Row>
            <CatalogItem onComplete={this.complete} config={this.props.config} />
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}

export default ItemsPage;
