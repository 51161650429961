import React, { Component } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { hot } from 'react-hot-loader';
import AWS from 'aws-sdk';
import 'bootstrap/dist/css/bootstrap.css';
import { Persist } from 'react-persist';
import { CognitoUserPool, CognitoUser, CognitoRefreshToken } from 'amazon-cognito-identity-js';
import Notifications from 'react-notify-toast';
import firebase from 'firebase';
import '../../scss/app.scss';
import Router from './Router';
import Config from '../Config';

AWS.config.update({
  region: 'ap-southeast-1',
});

firebase.initializeApp({
  apiKey: 'AIzaSyDVrdpAPQCuCjmTQqKQJ7-RY20ODi7dZss',
  messagingSenderId: '976498823561'
});

//let messaging = {};

class App extends Component {
  constructor() {
    super();

    /*if (firebase.messaging.isSupported()) {
      messaging = firebase.messaging();
      messaging.usePublicVapidKey(Config.VAPID_KEY);
    }*/

    this.state = {
      loading: false,
      loaded: true,
      config: {
        user: {
          idToken: '',
          accessToken: '',
          expiry: 0,
          username: '',
          name: '',
          email: '',
          phone: '',
          refreshToken: ''
        },
      },
      basket: [],
      notification: [],
      fcm: {
        fcmToken: '',
        allow: true
      }
    };

    this.updateConfig = this.updateConfig.bind(this);
    this.updateBasket = this.updateBasket.bind(this);
    window.notify = this.notify.bind(this);
    this.removeNotification = this.removeNotification.bind(this);
  }

  componentDidMount() {
    setTimeout(() => this.setState({ 
      loading: false,
      loaded: true
    }), 500);

    setInterval(() => {
      //if (this.state.config.user.expiry !== 0) {
      const userPool = new CognitoUserPool({
        UserPoolId: 'ap-southeast-1_AG2entuKs',
        ClientId: '6m2mv1h68apk1n6brmsqso6fg7'
      });
      const userData = {
        Username: this.state.config.user.username,
        Pool: userPool
      };
      const cognitoUser = new CognitoUser(userData);
      const refreshToken = new CognitoRefreshToken({ RefreshToken: this.state.config.user.refreshToken });
      cognitoUser.refreshSession(refreshToken, (err, result) => {
        if (err) {
          window.location = '/login';
        } else {
          const config = {
            user: {
              idToken: result.idToken.jwtToken,
              accessToken: result.getAccessToken().getJwtToken(),
              refreshToken: result.getRefreshToken().getToken(),
              expiry: result.idToken.payload.exp * 1000,
              id: result.accessToken.payload.username,
              username: this.state.config.user.username,
              password: this.state.config.user.password,
              name: result.idToken.payload.name,
              email: result.idToken.payload.email,
              phone: result.idToken.payload.phone_number,
              branches: this.state.config.user.branches
            },
          };
  
          this.updateConfig(config);
        }
      });
      //}
    }, 15 * 60 * 1000);

    /*if (firebase.messaging.isSupported()) {
      messaging.requestPermission().then(() => {
        console.log('Notification permission granted.');
  
        messaging.getToken().then((currentToken) => {
          if (currentToken) {
            console.log('curr', currentToken);
            this.setState({
              fcm: {
                fcmToken: currentToken,
                allow: this.state.fcm.allow
              }
            });
          } else {
            console.log('No Instance ID token available. Request permission to generate one.');
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
      }).catch((err) => {
        console.log('Unable to get permission to notify.', err);
      });
  
      messaging.onTokenRefresh(() => {
        messaging.getToken().then((refreshedToken) => {
          console.log('Token refreshed.');
        }).catch((err) => {
          console.log('Unable to retrieve refreshed token ', err);
        });
      });
  
      messaging.onMessage((payload) => {
        this.notify(payload);
      });
    }*/
  }

  notify = (payload) => {
    const arr = this.state.notification;
    arr.push({
      title: payload.notification.title,
      body: payload.notification.body
    });

    this.setState({
      notification: arr
    }, () => {
      console.log('Message received. ', payload);
    });
  }

  updateConfig(e) {
    this.setState({ config: e }, () => {
      console.log('e', this.state.config);
    });
  }

  updateBasket(e) {
    this.setState({
      basket: e
    });
  }

  removeNotification() {
    this.setState({
      notification: []
    });
  }

  render() {
    const { loaded, loading } = this.state;
    return (
      <div>
        {!loaded &&
        <div className={`load${loading ? '' : ' loaded'}`}>
          <div className="load__icon-wrap">
            <svg className="load__icon">
              <path fill="#c88ffa" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
            </svg>
          </div>
        </div>
        }
        <div>
          <Router 
            updateConfig={this.updateConfig} 
            config={this.state.config} 
            updateMenu={this.updateMenu} 
            menu={this.state.menu} 
            updateBasket={this.updateBasket} 
            basket={this.state.basket} 
            notification={this.state.notification} 
            removeNotification={this.removeNotification}
            token={this.state.fcm.fcmToken} />
        </div>
        <Notifications />
        <Persist 
          name="appuser" 
          data={{
            config: this.state.config
          }} 
          debounce={100} 
          onMount={(data) => {
            console.log('dpersist', data);
            this.setState({
              config: data.config
            });
          }}
        />
      </div>
    );
  }
}

export default hot(module)(App);
