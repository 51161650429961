/* eslint-disable react/no-unused-state */
import React, { PureComponent } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import EditTable from '../../EditableTable';

const MoneyFormatter = ({ value }) => (
  <div>
      ${value}
  </div>);

MoneyFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const ViewFormatter = ({ value }) => (
  <Link className="badge badge-success" to={`/pages/view?track=${value}`}>View</Link>);

ViewFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

const StatusFormatter = ({ value }) => (
  value ? <span className="badge badge-success">Delivered</span> :
  <span className="badge badge-disabled">Pending</span>
);

StatusFormatter.propTypes = {
  value: PropTypes.string.isRequired,
};

export default class DeliveryListTable extends PureComponent {
  static propTypes = {
    data: PropTypes.array
  };

  constructor() {
    super();
    this.heads = [
      {
        key: 'invoice',
        name: 'Order Number',
        sortable: true,
      },
      {
        key: 'po',
        name: 'PO Number',
        sortable: true,
      },
      {
        key: 'date',
        name: 'Delivery Date',
        sortable: true,
      },
      {
        key: 'time',
        name: 'Time',
        sortable: true,
      },
      {
        key: 'name',
        name: 'Customer Name',
        sortable: true,
      },
      {
        key: 'item_name',
        name: 'Item Name',
        sortable: true
      },
      {
        key: 'price',
        name: 'Unit Price',
        sortable: true,
        formatter: MoneyFormatter,
      },
      {
        key: 'quantity',
        name: 'Qty',
        sortable: true,
      },
      {
        key: 'total',
        name: 'Total',
        sortable: false
      },
      {
        key: 'delivered',
        name: 'Delivered',
        sortable: true,
        formatter: StatusFormatter,
      },
      {
        key: 'tracker',
        name: ' ',
        sortable: false,
        formatter: ViewFormatter
      },
    ];

    this.state = {
      pageOfItems: [],
    };
  }
  
  componentWillReceiveProps(newProps) {
    console.log('props', newProps);
  }

  onChangePage = (pageOfItems) => {
    // update state with new page of items
    this.setState({ pageOfItems });
  };

  onSelectSize = (e) => {
    this.setState({
      pageSize: parseInt(e.target.value, 10)
    });
  }
  
  render() {
    return (
      <Col md={12} lg={12}>
        {this.props.data.length > 0
          && <Card>
            <CardBody>
              <div className="card__title">
                <h5 className="bold-text">Orders list</h5>
              </div>
              <EditTable heads={this.heads} rows={this.props.data} />
            </CardBody>
          </Card>
        }
      </Col>
    );
  }
}
