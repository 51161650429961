import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';

class SidebarContent extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    this.props.onClick();
  };

  render() {
    return (
      <div className="sidebar__content">
        <ul className="sidebar__block">
          <SidebarLink title="Dashboard" icon="home" route="/pages/dashboard" onClick={this.hideSidebar} />
          <SidebarLink title="Element" icon="license" route="/pages/elements" onClick={this.hideSidebar} />
          <SidebarLink title="Receipt" icon="diamond" route="/pages/receipt" onClick={this.hideSidebar} />
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="Categories" icon="home" route="/pages/categories" onClick={this.hideSidebar} />
          <SidebarLink title="Create Order" icon="cart" route="/pages/order" onClick={this.hideSidebar} />
          <SidebarLink title="Import Excel" icon="rocket" route="/pages/load" onClick={this.hideSidebar} />
          <SidebarLink title="Items" icon="diamond" route="/pages/items" onClick={this.hideSidebar} />
        </ul>
      </div>
    );
  }
}

export default SidebarContent;
