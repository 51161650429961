import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Alert, Card, CardBody, Col, Container, Row, Button, ButtonToolbar } from 'reactstrap';
import PropTypes from 'prop-types';
import ContactIcon from 'mdi-react/PhoneIcon';
import UserIcon from 'mdi-react/UserIcon';
import PriceIcon from 'mdi-react/MoneyIcon';
import TimeIcon from 'mdi-react/ClockIcon';
import queryString from 'query-string';
import { notify } from 'react-notify-toast';
import Config from '../Config';
import LoadingScreen from '../../shared/components/loading';
import Logo from '../../shared/img/logo.png';

const getFrontId = (date) => {
  return (((date.getFullYear() % 100) * 10000) + ((date.getMonth() + 1) * 100) + date.getDate()).toString();
};

class ViewOrderPage extends Component {
  static propTypes = {
    config: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object
  };

  state = {
    loading: true,
    id: 0,
    timestamp: 0,
    ordertime: 0,
    branch_id: '',
    items: [],
    delivered: true,
    name: '',
    price: 0,
    type: '',
    address: '',
    notes: '',
    error: ''
  };

  componentDidMount() {
    const queries = queryString.parse(this.props.location.search);
    this.load(parseInt(queries.track, 10));
  }

  formatDateTime = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hour = date.getHours();
    const min = date.getMinutes();

    return `${day}/${month}/${year} (${('0' + hour.toString()).slice(-2)}:${('0' + min.toString()).slice(-2)})`;
  }

  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  load = (track) => {
    this.setState({
      delivery_id: track
    });

    const params = {
      delivery_id: track
    };

    const xhr = this.createCORSRequest('POST', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/delivery');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);
    
    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        this.setState({
          items: body.order.items,
          id: body.order.delivery_id,
          timestamp: body.order.deliver_at,
          ordertime: body.order.created_at,
          name: body.order.details.name,
          contact: body.order.details.contact,
          price: body.order.paid_amount,
          delivered: body.order.delivered,
          address: body.order.details ? body.order.details.address : '',
          notes: body.order.details ? body.order.details.note : '',
          sign: body.order.sign,
          deliverer: body.order.deliver_by
        }, () => {
          console.log('tt', this.state);
        });
      } else {
        this.setState({ error: body.message });
        console.log('e', body.message);
      }
      this.setState({ loading: false });
    };

    xhr.send(JSON.stringify(params));
  }

  deleteOrder = () => {
    this.setState({
      loading: true
    });

    const params = {
      delivery_id: this.state.delivery_id
    };

    const xhr = this.createCORSRequest('POST', 'https://g4jhdo6hd3.execute-api.ap-southeast-1.amazonaws.com/v1/delivery/delete');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('x-api-key', Config.API_KEY);
    xhr.setRequestHeader('Authorization', this.props.config.user.idToken);
    console.log('p', params);
    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      const body = JSON.parse(data.body);
      if (data.statusCode === 200) {
        notify.show('Item deleted successfully', 'custom', 5000, {
          background: '#343a40',
          text: '#ddae71'
        });

        this.props.history.push('/pages/dashboard');
      } else {
        this.setState({ error: body.message });
        console.log('e', body.message);
      }
      this.setState({ loading: false });
    };

    xhr.send(JSON.stringify(params));
  }

  createCORSRequest(method, url) {
    let xhr = new XMLHttpRequest();
    if ('withCredentials' in xhr) {
      xhr.open(method, url, true);
    } else {
      xhr = null;
    }
    return xhr;
  }

  render() {
    return (
      <LoadingScreen
        loading={this.state.loading}
        bgColor="#FFFFFF"
        spinnerColor="#d28b41"
        spinnerType="ball-clip-rotate"
        textColor="#343a40"
        logoSrc={Logo}
      >
        <Container className="dashboard">
          <Alert color="danger" className="bold-text" isOpen={this.state.error}>
            <div className="alert__content">
              {this.state.error}
            </div>
          </Alert>
          <Row>
            <Col md={12}>
              <h3 className="page-title">Order #{getFrontId(new Date(this.state.timestamp))}{this.state.id.toString().slice(-4)}</h3>
            </Col>
          </Row>
          <Row hidden={this.state.loading}>
            <Col md={4}>
              <Card>
                <CardBody>
                  {this.state.items.map((item) => (
                    <div className="checkoutlist__collapse-item" key={item.id}>
                      <p className="checkoutlist__collapse-name">{item.name} (${(item.price / 100).toFixed(2)})</p>
                      <p className="checkoutlist__collapse-date">x{item.qty} (${((item.price * item.qty) / 100).toFixed(2)})</p>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
            <Col md={8}>
              <Card>
                <CardBody>
                  <div className="card__title">
                    <h5 className="bold-text">Order Note</h5>
                    <h5 className="subhead">Enter additional information for record</h5>
                  </div>
                  <form className="form">
                    <div className="form__form-group">
                      <span className="form__form-group-label">Total Price</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <PriceIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="text"
                          value={(this.state.price / 100).toFixed(2)}
                          disabled={true}
                          placeholder="Total Price"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Name</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <UserIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="text"
                          value={this.state.name}
                          disabled={true}
                          placeholder="Name"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Contact Number</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <ContactIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="tel"
                          value={this.state.contact}
                          disabled={true}
                          placeholder="Contact Number (optional)"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Delivery Status</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <TimeIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="tel"
                          value={this.state.delivered ? 'Delivered' : 'Pending'}
                          disabled={true}
                          placeholder="Contact Number (optional)"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Created At</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <TimeIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="text"
                          value={this.formatDateTime(new Date(this.state.ordertime))}
                          disabled={true}
                          placeholder="Address (optional)"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Delivery Date</span>
                      <div className="form__form-group-field">
                        <div className="form__form-group-icon">
                          <TimeIcon />
                        </div>
                        <input
                          name="leftIcon"
                          component="input"
                          type="text"
                          value={this.formatDateTime(new Date(this.state.timestamp))}
                          disabled={true}
                          placeholder="Address (optional)"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Address</span>
                      <div className="form__form-group-field">
                        <textarea
                          name="textarea"
                          type="text"
                          rows="10"
                          value={this.state.address}
                          disabled={true}
                          placeholder="None"
                        />
                      </div>
                    </div>
                    <div className="form__form-group">
                      <span className="form__form-group-label">Notes</span>
                      <div className="form__form-group-field">
                        <textarea
                          name="textarea"
                          type="text"
                          rows="10"
                          value={this.state.notes}
                          disabled={true}
                          placeholder="None"
                        />
                      </div>
                    </div>
                    {this.state.deliverer
                      && <div className="form__form-group">
                        <span className="form__form-group-label">Delivered By</span>
                        <div className="form__form-group-field">
                          <div className="form__form-group-icon">
                            <UserIcon />
                          </div>
                          <input
                            name="leftIcon"
                            component="input"
                            type="text"
                            value={this.state.deliverer}
                            disabled={true}
                            placeholder="Delivered By"
                          />
                        </div>
                      </div>
                    }
                    {this.state.sign
                      && <div className="form__form-group">
                        <span className="form__form-group-label">Recipient Signature</span>
                        <div className="form__form-group-field">
                          <img
                            className="sig"
                            src={this.state.sign}
                            alt="signature"
                          />
                        </div>
                      </div>
                    }
                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="primary" onClick={() => this.deleteOrder()} disabled={this.state.collected}>Delete</Button>
                    </ButtonToolbar>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </LoadingScreen>
    );
  }
}
    
export default withRouter(ViewOrderPage);
