import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
import TopbarNotification from './TopbarNotification';

class Topbar extends Component {
  static propTypes = {
    changeMobileSidebarVisibility: PropTypes.func.isRequired,
    changeSidebarVisibility: PropTypes.func.isRequired,
    config: PropTypes.object,
    updateBasket: PropTypes.func,
    basket: PropTypes.array,
    notification: PropTypes.array,
    removeNotification: PropTypes.func
  };

  render() {
    const { changeMobileSidebarVisibility, changeSidebarVisibility, config } = this.props;

    return (
      <div className="topbar">
        <div className="topbar__wrapper">
          <div className="topbar__left">
            <TopbarSidebarButton
              changeMobileSidebarVisibility={changeMobileSidebarVisibility}
              changeSidebarVisibility={changeSidebarVisibility}
            />
            <Link className="topbar__logo" to="/pages/dashboard" />
          </div>
          <div className="topbar__right">
            <TopbarNotification notification={this.props.notification} removeNotification={this.props.removeNotification} new />
            <TopbarProfile config={config} />
          </div>
        </div>
      </div>
    );
  }
}

export default Topbar;
