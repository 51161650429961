import React, { Component } from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import ProductItems from './ProductItems';

class CatalogItem extends Component {
  static propTypes = {
    config: PropTypes.object,
    update: PropTypes.func,
    basket: PropTypes.array,
    complete: PropTypes.func
  };

  render() {
    return (
      <Col md={12} lg={12}>
        <ProductItems complete={this.props.complete} config={this.props.config} basket={this.props.basket} update={this.props.update} />
      </Col>
    );
  }
}

export default CatalogItem;
